import { RouterState } from '@models';
import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectRouterState =
  createFeatureSelector<RouterReducerState<RouterState>>('router');
export const selectCurrentRoute = createSelector(selectRouterState, router => {
  return router?.state ?? {};
});
export const selectAllowedRole = createSelector(
  selectRouterState,
  router => router.state?.data?.allowedRole
);
export const selectRouterData = createSelector(
  selectRouterState,
  router => router?.state?.data ?? {}
);

export function selectRouteParams<
  T extends RouterState['params'] = RouterState['params'],
>() {
  return createSelector(
    selectRouterState,
    router => (router?.state?.params ?? {}) as T
  );
}

export function selectQueryParams<
  T extends RouterState['queryParams'] = RouterState['queryParams'],
>() {
  return createSelector(
    selectRouterState,
    router => (router?.state?.queryParams ?? {}) as T
  );
}
